import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { productStockAPI } from '../../../controllers/reports/reportControllers';
import { colors } from '../../../assets/styles/colors';
import { formatAmount } from '../../../shared/utils/locale';
import ScreenWrapper from '../../../components/wrappers/ScreenWrapper';
import InputForm from '../../../components/forms/InputForm';
import Button, { TextButton } from '../../../components/buttons/Button';
import ProductStockDetail from './ProductStockDetail';
import {
  TableRowStyled,
  TbRowHeaderStyled,
} from '../../../components/styled/TableRowStyled';
import {
  TbCellBody,
  TbCellHeader,
} from '../../../components/styled/TableCellStyled';
import {
  dateFileName,
  getSettingBasedDate,
} from '../../../shared/utils/getSettingBasedDate';
import { useSelector } from '../../../store/reduxHooks';
import { productStockTemplate } from '../../../templates/productStockTemplate';
import { createPDF } from '../../../shared/utils/createPDF';

const initial = { initialData: [] };

function ProductStockReport() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useSelector(s => s.auth.authUser);

  const [filteredItems, setFilteredItems] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [showStockDetail, setShowStockDetail] = useState<boolean>(false);
  const [selectedProdItem, setSelectedProdItem] = useState<any>({});
  const [pdfSource, setPdfSource] = useState<any>({ uri: '' });

  const result = useQuery(['productStock'], productStockAPI);
  const { isLoading, isError, data = [] } = result;

  const onSearch = (inputText: string) => {
    const text = inputText.toLowerCase();
    setSearchText(inputText);

    const filtered = data.filter((product: any) => {
      const { name, currentStock } = product;
      const categoryName = product.category?.name;

      const hasName = name?.toLowerCase().includes(text);
      const hasCatName = categoryName?.toLowerCase().includes(text);
      const hasCurrentStk = String(currentStock)?.toLowerCase().includes(text);

      if (hasName || hasCatName || hasCurrentStk) {
        return true;
      }
    });
    setFilteredItems(filtered);
  };

  let productList = data;
  if (searchText) {
    productList = filteredItems;
  }

  const getProductPrice = (productItem: any) => {
    const purPrice = `${formatAmount(productItem.purchasePrice)}`;
    const selPrice = `${formatAmount(productItem.sellingPrice)}`;
    return `${purPrice} - ${selPrice}`;
  };

  // console.log('salesReport', salesReport.data);
  // console.log('filtersL:', filters);

  // const dateRange = { fromDate: filters.fromDate, toDate: filters.toDate };
  const tbCell = { fontWeight: 'bold', textAlign: 'right' };

  const onCreatePDF = async () => {
    const now = new Date();
    const createdDate = getSettingBasedDate(now, user);
    const createdTime = now.toLocaleTimeString();

    const datax = {
      user,
      productList: productList,
      createdAt: `${createdDate} ${createdTime}`,
    };

    const template = productStockTemplate(datax);

    const fileName = `product_stocks_${dateFileName(user)}`.toLowerCase();
    const source = await createPDF(template, fileName);
    setPdfSource(source);
  };

  if (showStockDetail) {
    return (
      <ProductStockDetail
        productItem={selectedProdItem}
        onCloseDetail={() => setShowStockDetail(false)}
      />
    );
  }

  return (
    <Card sx={{ paddingX: 4 }}>
      <CardContent sx={{ paddingY: 1 }}>
        <Stack pb={1.6} direction={'row'} spacing={2}>
          <Box flex={1}>
            <Grid
              container
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              alignItems={'center'}>
              <Grid xs={8}>
                <Typography sx={{ fontSize: 20 }}>
                  {t('reportStack.prodStock')} ({productList.length})
                </Typography>
              </Grid>
              <Grid xs={4}>
                <InputForm
                  size="small"
                  inLineLabel={true}
                  label={'Search by product name, category or stock...'}
                  onChangeText={(v: string) => onSearch(String(v))}
                />
              </Grid>
            </Grid>
          </Box>
          {data?.length > 0 && (
            <IconButton
              sx={{ backgroundColor: colors.gray300 }}
              onClick={onCreatePDF}>
              <PictureAsPdfIcon sx={{ color: colors.theme1, fontSize: 28 }} />
            </IconButton>
          )}
          <Box display={'flex'} alignItems={'flex-end'} pb={0.4}>
            <Button
              size="md"
              title="Add Product"
              onClick={() => navigate('/settings/products/create')}
            />
          </Box>
        </Stack>
        <Divider />

        <ScreenWrapper {...{ isLoading, isError, isEmpty: !data.length }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TbRowHeaderStyled>
                  <TbCellHeader style={{ maxWidth: 24 }}>SN</TbCellHeader>
                  <TbCellHeader>Product</TbCellHeader>
                  <TbCellHeader>Product Category</TbCellHeader>
                  <TbCellHeader>Purchase Price</TbCellHeader>
                  <TbCellHeader>Selling Price</TbCellHeader>
                  <TbCellHeader align="right">Stock</TbCellHeader>
                  <TbCellHeader />
                </TbRowHeaderStyled>
              </TableHead>
              <TableBody>
                {productList.map((row, index) => (
                  <TableRowStyled key={row.id}>
                    <TbCellBody sx={{ maxWidth: 24 }}>{index + 1}</TbCellBody>
                    <TbCellBody>{row.name}</TbCellBody>
                    <TbCellBody>{row.category?.name}</TbCellBody>
                    <TbCellBody>{row.purchasePrice}</TbCellBody>
                    <TbCellBody>{row.sellingPrice}</TbCellBody>
                    <TbCellBody align="right" sx={{ fontWeight: 'bold' }}>
                      {row.currentStock} {row.primaryUnit?.name}
                    </TbCellBody>
                    <TbCellBody align="right">
                      <TextButton
                        size="sm"
                        title="details"
                        sx={{ px: 1, py: 0, fontWeight: 'bold' }}
                        onClick={() => {
                          // navigate('/reports/stock-movement', { state: row })
                          setSelectedProdItem(row);
                          setShowStockDetail(true);
                        }}
                      />
                    </TbCellBody>
                  </TableRowStyled>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ScreenWrapper>
      </CardContent>
    </Card>
  );
}

export default ProductStockReport;
