import { store } from '../../store/store';
import {
  generalVoucherUrl,
  recentTransactionsUrl,
  transactionsCountUrl,
  updateVoucherUrl,
  voucherDeleteCheckUrl,
  voucherDeleteUrl,
  vouchersUrl,
  voucherUrl,
  voucherForEditUrl,
} from '../endPoints';

const httpClient = () => {
  return store.getState().http.client;
};

const v1 = (path: string) => `/v1${path}`;
const v2 = (path: string) => `/v2${path}`;

export const getVouchers = async (filters: any) => {
  // const path = vouchersUrl();
  const path = v2('/transactions/vouchers');

  const response = await httpClient().get<any>(path, filters);
  return response.data;
};

export const getVoucher = async (voucherId: string) => {
  const path = voucherUrl(voucherId);

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const getVoucherForEdit = async (voucherId: string) => {
  const path = voucherForEditUrl(voucherId);

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const createVoucher = async (data: any) => {
  const path = generalVoucherUrl();
  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const updateVoucher = async () => {
  const path = updateVoucherUrl(9);

  const updated = {
    code: 98976,
    name: 'partner updated test34',
    description: 'from app',
  };

  const response = await httpClient().patch<any>(path, updated);
  return response.data;
};

export const getRecentTransactions = async () => {
  const path = recentTransactionsUrl();

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const getTransactionsCount = async () => {
  const path = transactionsCountUrl();

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const getVouchersToDelete = async (voucherId: string) => {
  const path = voucherDeleteCheckUrl(voucherId);

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const deleteVoucher = async (voucherId: string) => {
  const path = voucherDeleteUrl(voucherId);

  const response = await httpClient().delete<any>(path);
  return response.data;
};

export const updateTxnCancelStatus = async (voucherId: number) => {
  const path = v1(`/transactions/cancel-status/${voucherId}`);

  const response = await httpClient().patch<any>(path);
  return response.data;
};
