import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Card from '@mui/material/Card';
import {
  Box,
  Button,
  CardContent,
  Chip,
  Divider,
  IconButton,
  Stack,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from 'react-router-dom';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';

import { getAccounts } from '../../controllers/settings/accountControllers';
import InputForm from '../../components/forms/InputForm';
import { queryStatus } from '../../shared/utils/queryUtils';
import { useTranslation } from 'react-i18next';
import { _7DaysAgo, LAST_7_DAYS } from '../../components/forms/PeriodSelect';
import { getVouchers } from '../../controllers/transactions/voucherControllers';
import { validateVoucherEntriesFilters } from '../../validations/transactions/validateVoucherEntriesFilter';
import { useSelector } from '../../store/reduxHooks';
import voucherTypes, {
  salesPurchaseVouchers,
  vendorVouchers,
} from '../../constants/defaultCodes/voucherTypes';
import ScreenWrapper from '../../components/wrappers/ScreenWrapper';
import TxnEntriesTableItem from './components/TxnEntriesTableItem';
import TransactionFilterModal from './components/TransactionFilterModal';
import DateRangeCard from '../../components/datePickers/DateRangeCard';
import { TbRowHeaderStyled } from '../../components/styled/TableRowStyled';
import {
  TbCellAmount,
  TbCellHeader,
  TbCellSummary,
} from '../../components/styled/TableCellStyled';
import { colors } from '../../assets/styles/colors';
import { formatAmount } from '../../shared/utils/locale';

const JournalEntries = (): JSX.Element => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { user } = useSelector(state => state.auth.authUser);

  const today = new Date();
  const todayEnd = today.setHours(23, 59, 59, 0); // set to 11:59 PM

  const initSt: any = { fromDate: _7DaysAgo, toDate: new Date(todayEnd) };
  const [filters, setFilters] = useState<any>({ ...initSt });
  const [errors, setErrors] = useState<any>({});
  const [showFilters, setShowFilter] = useState(false);
  const [applied, setApplied] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState<string>(LAST_7_DAYS);
  const [refresh, setRefresh] = useState(false);
  // const [showMenu, setShowMenu] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [filteredItems, setFilteredItems] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>('');

  const enable = { enabled: true, cacheTime: 0 };
  const getVoucherFn = () => getVouchers(filters);
  const voucherQuery = useQuery(['vouchers'], getVoucherFn, enable);
  // const { isLoading, isError, data: entries = [] } = voucherQuery;
  const { isLoading, isError, data = {} } = voucherQuery;

  const totalTxnAmount = data.totalTxnAmount;
  const entries = data.voucherList || [];

  const onSubmit = async () => {
    setApplied(true);
    const { isValid, errs } = validateVoucherEntriesFilters(filters, errors);

    if (isValid) {
      setShowFilter(false);
      voucherQuery.refetch();
    } else {
      setErrors(errs);
      console.log('Validation Failed');
    }
  };

  const refetchVouchers = async () => {
    voucherQuery.refetch();
  };

  const onFilterChange = (name: string, value: any) => {
    setFilters((prev: any) => ({ ...prev, [name]: value }));

    const updatedData = { ...filters, [name]: value };
    const { errs } = validateVoucherEntriesFilters(updatedData, errors);
    errs[name] ? setErrors({ [name]: errs[name] }) : setErrors({});
  };

  const onDateChange = (dateRange: any, period: string) => {
    const updatedData = { ...filters, ...dateRange };
    setFilters(updatedData);
    setSelectedPeriod(period);
  };

  const getTitle = () => {
    if (applied && filters.voucherType) {
      return filters.voucherType.split('_').join(' ').toUpperCase();
    } else {
      return t('voucherEntries.text.allTransaction');
    }
  };

  const onSearch = (inputText: string) => {
    const text = inputText.toLowerCase();
    setSearchText(inputText);

    const filtered = entries.filter((txn: any) => {
      const { referenceId, voucherNumber, voucherType, txnAmount } = txn;
      const { debitSum, aging } = txn;

      const hasRef = referenceId?.toLowerCase().includes(text);
      const hasVNo = voucherNumber.toLowerCase().includes(text);
      const hasVType = voucherType.code?.toLowerCase().includes(text);
      const hasTxnAmt = String(txnAmount)?.toLowerCase().includes(text);
      const hasAmt = String(debitSum)?.toLowerCase().includes(text);
      const hasAge = String(aging)?.toLowerCase().includes(text);

      if (hasRef || hasVNo || hasVType || hasTxnAmt || hasAmt || hasAge) {
        return true;
      }
    });
    setFilteredItems(filtered);
  };

  let transcationList = entries;
  if (searchText) {
    transcationList = filteredItems;
  }

  // console.log('filters:', filters);
  // console.log('entries:', entries);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    // setShowMenu(true)
  };

  const isCancellableTxn = (type: string) => {
    if (salesPurchaseVouchers.includes(type)) {
      return true;
    }
    return false;
  };

  const isDeletableTxn = (type: string) => {
    if (voucherTypes.ACCRUED_INTEREST === type) {
      return false;
    }
    return true;
  };

  const showActionMenu = Boolean(anchorEl);

  return (
    <Card sx={{ paddingX: 4 }}>
      <CardContent sx={{ paddingY: 1 }}>
        <Stack pb={1} flexDirection={'row'} alignItems={'center'} gap={4}>
          <Stack flex={2} flexDirection={'row'} alignItems={'center'} gap={1.4}>
            <Typography sx={{ fontSize: 20 }}>
              {/* {getTitle().toUpperCase()} ({entries?.length}) */}
              {getTitle().toUpperCase()}
            </Typography>
            <Typography sx={{ fontSize: 28 }}>|</Typography>
            <Stack alignItems={'flex-start'}>
              <DateRangeCard {...{ filters }} />
            </Stack>
          </Stack>

          <Stack pb={0.6} flex={1}>
            <InputForm
              size="small"
              inLineLabel={true}
              label={'Search by type, ref no, voucher no, age or amount...'}
              onChangeText={(v: string) => onSearch(String(v))}
            />
          </Stack>

          <Stack direction="row" justifyContent={'flex-end'}>
            <Button
              variant="contained"
              onClick={() => {
                setShowFilter(true);
                setApplied(false);
              }}>
              Other Filters
            </Button>
          </Stack>
        </Stack>

        <Divider />

        <ScreenWrapper {...{ isError, isLoading, isEmpty: !entries?.length }}>
          <Stack>
            <TableContainer>
              <Table aria-label="table">
                <TableHead>
                  <TbRowHeaderStyled>
                    <TbCellHeader sx={{ maxWidth: 20 }}>SN</TbCellHeader>
                    <TbCellHeader>Transaction Date</TbCellHeader>
                    <TbCellHeader>Transaction Type</TbCellHeader>
                    <TbCellHeader>Partner</TbCellHeader>
                    <TbCellHeader>Ref No.</TbCellHeader>
                    <TbCellHeader>Voucher No.</TbCellHeader>
                    <TbCellHeader>Age</TbCellHeader>
                    <TbCellHeader align="right">Amount</TbCellHeader>
                    <TbCellHeader />
                    <TbCellHeader />
                  </TbRowHeaderStyled>
                </TableHead>
                <TableBody>
                  {transcationList.map((voucher, index) => (
                    <TxnEntriesTableItem
                      key={index}
                      index={index + 1}
                      voucher={voucher}
                      isReportScreen={false}
                      refetchVouchers={refetchVouchers}
                    />
                  ))}
                </TableBody>
                <TableHead sx={{ backgroundColor: colors.gray200 }}>
                  <TableRow>
                    <TbCellSummary />
                    <TbCellAmount colSpan={6} sx={{ fontStyle: 'italic' }}>
                      Total Amount
                    </TbCellAmount>
                    <TbCellSummary>
                      {formatAmount(totalTxnAmount)}
                    </TbCellSummary>
                    <TbCellSummary />
                    <TbCellSummary />
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </Stack>
        </ScreenWrapper>
      </CardContent>
      {showFilters && (
        <TransactionFilterModal
          isLoading={false}
          errors={errors}
          toReturn={false}
          isReportScreen={false}
          onSubmit={onSubmit}
          filters={filters}
          selectedPeriod={selectedPeriod}
          onFilterChange={onFilterChange}
          onDateChange={onDateChange}
          modalVisible={showFilters}
          onModalClose={() => setShowFilter(false)}
        />
      )}
    </Card>
  );
};

export default JournalEntries;
