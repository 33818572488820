import React, { useEffect, useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import StorefrontRoundedIcon from '@mui/icons-material/StorefrontRounded';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import {
  Calculate,
  EmojiEvents,
  Outbound,
  Paid,
  Receipt,
  SettingsApplications,
  YouTube,
} from '@mui/icons-material';

import { NavLink, useLocation } from 'react-router-dom';
import { Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { colors } from '../../assets/styles/colors';
import { isSystemSuperAdmin } from '../../shared/utils/rolesUtils';

const linkStyle = {
  color: 'inherit',
  textDecoration: 'inherit',
};

const listItmBtn = {
  paddingY: { md: 2.2, xl: 2.6 },
  paddingX: 0,
  gap: 1,
};

export const mainListItems = (authUser: any) => {
  const { t } = useTranslation();
  const location = useLocation();

  const paths = location.pathname.split('/').filter(x => x);
  const defaultValue = paths.length ? paths[0] : 'dashboard';
  const [active, setActive] = useState(defaultValue);
  // console.log('defaultValue:', defaultValue);

  useEffect(() => {
    if (defaultValue) {
      setActive(defaultValue);
    }
  }, [defaultValue]);

  const activeStyle = (activeTab: string) => {
    const text: any = {};

    if (active === activeTab) {
      text.color = colors.theme1;
      text.fontWeight = 'bold';
    }

    return text;
  };

  const isSystemAdmin = isSystemSuperAdmin(authUser.user);

  return (
    <React.Fragment>
      <NavLink end to={'/'} style={linkStyle}>
        <ListItemButton
          sx={{
            ...listItmBtn,
            // '&:hover': { backgroundColor: colors.info50 },
            backgroundColor: active === 'dashboard' && colors.gray200,
          }}
          onClick={() => setActive('dashboard')}>
          <ListItemIcon sx={{ paddingX: 3 }}>
            <DashboardIcon sx={activeStyle('dashboard')} />
          </ListItemIcon>
          <Typography sx={activeStyle('dashboard')}>
            {t('tabNavigation.dashboard')}
          </Typography>
        </ListItemButton>
      </NavLink>

      <NavLink end to={'/transaction'} style={linkStyle}>
        <ListItemButton
          sx={{
            ...listItmBtn,
            // '&:hover': { backgroundColor: colors.info50 },
            backgroundColor: active === 'transaction' && colors.gray200,
          }}
          onClick={() => setActive('transaction')}>
          <ListItemIcon sx={{ paddingX: 3 }}>
            <Calculate sx={activeStyle('transaction')} />
          </ListItemIcon>
          <Typography sx={activeStyle('transaction')}>
            {t('tabNavigation.transaction')}
          </Typography>
        </ListItemButton>
      </NavLink>

      <NavLink end to={'/journals'} style={linkStyle}>
        <ListItemButton
          sx={{
            ...listItmBtn,
            // '&:hover': { backgroundColor: colors.info50 },
            backgroundColor: active === 'journals' && colors.gray200,
          }}
          onClick={() => setActive('journals')}>
          <ListItemIcon sx={{ paddingX: 3 }}>
            <Receipt sx={activeStyle('journals')} />
          </ListItemIcon>
          <Typography sx={activeStyle('journals')}>
            {t('tabNavigation.journal')}
          </Typography>
        </ListItemButton>
      </NavLink>

      <NavLink end to={'/reports'} style={linkStyle}>
        <ListItemButton
          sx={{
            ...listItmBtn,
            // '&:hover': { backgroundColor: colors.info50 },
            backgroundColor: active === 'reports' && colors.gray200,
          }}
          onClick={() => setActive('reports')}>
          <ListItemIcon sx={{ paddingX: 3 }}>
            <BarChartIcon sx={activeStyle('reports')} />
          </ListItemIcon>
          <Typography sx={activeStyle('reports')}>
            {t('tabNavigation.report')}
          </Typography>
        </ListItemButton>
      </NavLink>

      <NavLink end to="/settings" style={linkStyle}>
        <ListItemButton
          sx={{
            ...listItmBtn,
            // '&:hover': { backgroundColor: colors.info50 },
            backgroundColor: active === 'settings' && colors.gray200,
          }}
          onClick={() => setActive('settings')}>
          <ListItemIcon sx={{ paddingX: 3 }}>
            <RoomPreferencesIcon sx={activeStyle('settings')} />
          </ListItemIcon>
          <Typography sx={activeStyle('settings')}>
            {t('tabNavigation.setting1')}
          </Typography>
        </ListItemButton>
      </NavLink>

      <Divider />

      <NavLink end to={'/receivables'} style={linkStyle}>
        <ListItemButton
          sx={{
            ...listItmBtn,
            // '&:hover': { backgroundColor: colors.info50 },
            backgroundColor: active === 'receivables' && colors.gray200,
          }}
          onClick={() => setActive('receivables')}>
          <ListItemIcon sx={{ paddingX: 3 }}>
            <Paid sx={activeStyle('receivables')} />
          </ListItemIcon>
          <Typography sx={activeStyle('receivables')}>
            {t('dashboard.title.receivable')}
          </Typography>
        </ListItemButton>
      </NavLink>

      <NavLink end to={'/payables'} style={linkStyle}>
        <ListItemButton
          sx={{
            ...listItmBtn,
            // '&:hover': { backgroundColor: colors.info50 },
            backgroundColor: active === 'payables' && colors.gray200,
          }}
          onClick={() => setActive('payables')}>
          <ListItemIcon sx={{ paddingX: 3 }}>
            <Outbound sx={activeStyle('payables')} />
          </ListItemIcon>
          <Typography sx={activeStyle('payables')}>
            {t('dashboard.title.payable')}
          </Typography>
        </ListItemButton>
      </NavLink>

      <NavLink end to={'/product-inventory'} style={linkStyle}>
        <ListItemButton
          sx={{
            ...listItmBtn,
            backgroundColor: active === 'product-inventory' && colors.gray200,
          }}
          onClick={() => setActive('product-inventory')}>
          <ListItemIcon sx={{ paddingX: 3 }}>
            <StorefrontRoundedIcon sx={activeStyle('product-inventory')} />
          </ListItemIcon>
          <Typography sx={activeStyle('product-inventory')}>
            {t('dashboard.title.stockValue')}
          </Typography>
        </ListItemButton>
      </NavLink>

      <Divider />

      <NavLink end to={'/subscription'} style={linkStyle}>
        <ListItemButton
          sx={{
            ...listItmBtn,
            // '&:hover': { backgroundColor: colors.info50 },
            backgroundColor: active === 'subscription' && colors.gray200,
          }}
          onClick={() => setActive('subscription')}>
          <ListItemIcon sx={{ paddingX: 3 }}>
            <EmojiEvents sx={activeStyle('subscription')} />
          </ListItemIcon>
          <Typography sx={activeStyle('subscription')}>
            {t('setting.subscription')}
          </Typography>
        </ListItemButton>
      </NavLink>

      {isSystemAdmin && (
        <NavLink end to={'/system-admin'} style={linkStyle}>
          <ListItemButton
            sx={{
              ...listItmBtn,
              backgroundColor: active === 'system-admin' && colors.gray200,
            }}
            onClick={() => setActive('system-admin')}>
            <ListItemIcon sx={{ paddingX: 3 }}>
              <AdminPanelSettingsIcon sx={activeStyle('system-admin')} />
            </ListItemIcon>
            <Typography sx={activeStyle('system-admin')}>
              System Admin
            </Typography>
          </ListItemButton>
        </NavLink>
      )}

      {/* <a href="https://www.youtube.com/@karodapp/videos" target="_blank">
        <ListItemButton
          sx={{
            ...listItmBtn,
            backgroundColor: active === 'learn-to-use' && colors.gray200,
          }}>
          <ListItemIcon sx={{ paddingX: 3 }}>
            <YouTube sx={activeStyle('learn-to-use')} />
          </ListItemIcon>
          <Typography sx={activeStyle('learn-to-use')}>
            {t('setting.tutorial')}
          </Typography>
        </ListItemButton>
      </a> */}
    </React.Fragment>
  );
};

export const mainListItems2 = (
  <React.Fragment>
    <NavLink end to={'/'} style={linkStyle}>
      <ListItemButton sx={{ paddingY: 2 }}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        Dashboard
      </ListItemButton>
    </NavLink>

    <NavLink end to={'/transactions'} style={linkStyle}>
      <ListItemButton sx={{ paddingY: 2 }}>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        Transactions
      </ListItemButton>
    </NavLink>

    <NavLink end to={'/reports'} style={linkStyle}>
      <ListItemButton sx={{ paddingY: 2 }}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        Reports
      </ListItemButton>
    </NavLink>

    <NavLink end to="/settings" style={linkStyle}>
      <ListItemButton sx={{ paddingY: 2 }}>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        Settings
      </ListItemButton>
    </NavLink>
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Subscription" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Learn To Use" />
    </ListItemButton>
  </React.Fragment>
);

export const secondaryListItems2 = () => {
  const [active, setActive] = useState('');

  return (
    <React.Fragment>
      <NavLink end to={'/'} style={linkStyle}>
        <ListItemButton
          sx={{
            paddingY: 2,
            backgroundColor: active === 'subscription' && colors.gray200,
          }}
          onClick={() => setActive('subscription')}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          Subscription
        </ListItemButton>
      </NavLink>

      <NavLink end to={'/support'} style={linkStyle}>
        <ListItemButton
          sx={{
            paddingY: 2,
            backgroundColor: active === 'support' && colors.gray200,
          }}
          onClick={() => setActive('support')}>
          <ListItemIcon>
            <ShoppingCartIcon />
          </ListItemIcon>
          Support
        </ListItemButton>
      </NavLink>
    </React.Fragment>
  );
};
